import React,{ useState , useEffect , Fragment } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import logo from './asset/logo.png'
import theme from './asset/bg.jpeg'
import starter from './asset/tandoor.jpeg'
import soup from './asset/soup.jpeg'
import salad from './asset/salad.jpeg'
import chines from './asset/chinies.jpeg'
import desert from './asset/desert.jpeg'
import bread from './asset/bread.jpeg'
import chicken_c from './asset/chicken_curry.jpeg'
import lassi from './asset/lassi.jpeg'
import kids from './asset/kids.jpeg'
import mutton from './asset/mutton.jpeg'
import shrimps from './asset/shrimps.jpeg'
import tandor from './asset/Tandoori_pic.jpeg'
import veg from './asset/veg-kolhapuri.jpeg'
import tandoori from './asset/tandoor.jpeg'
import biryani from './asset/biryani.png'


const Header = () => {

  const [active, setActive] = useState(false);

    return (





        <div className="m-layout m-layout--header-right items-center justify-center">
          <BrowserView>
        <header className=" m-layout__header m-layout__header--with-offset radius text-center flex-display" style={{background:"black", opacity:"0.8", }}>
          <div className="visible-xs-block visible-sm-block">
            <div className="m-header__content">
            <div className="m-header__brand">
                <div className="m-brand " style={{paddingTop:10 , paddingLeft:10}}>
                  <a href="/" className="m-brand__area">
                    <img
                      src={logo}

                      className="m-brand__logo"
                      alt="Indian"

                    />
                  </a>
                </div>
              </div>
              <div className="m-nav-toggle">
                <button
                  type="button"
                  className="m-nav-toggle__btn js-navigation-toggle"
                  title="Nawigacja"
                  aria-controls="responsive-nav"
                  data-ga-action="Open mobile navigation (hamburger button clicks)"
                >
                  <span
                    className="icon-reorder m-nav-toggle__icon"
                    aria-hidden="true"
                  ></span>
                </button>
              </div>
            </div>

            <div className="mobile-navigation-backdrop js-navigation-toggle"></div>
            <nav
              id="responsive-nav"
              className="mobile-navigation mobile-navigation--full js-navigation js-scroll-spy-nav"
              data-navigation='{"activeClassName": "is-active"}'
            >
              <header className="mobile-navigation__header">
                <button
                  type="button"
                  className="mobile-navigation-toggle js-navigation-toggle"
                  aria-controls="responsive-nav"
                  title="Zamknij"
                >
                  &times;
                </button>
              </header>


              <div className="mobile-navigation__slogan">

              </div>
            </nav>
          </div>
          <div className="m-header__wrapper hidden-xs hidden-sm">
            <div className="m-header__content ">
              <div className="m-header__brand">
                <div className="m-brand">
                  <a href="/" className="m-brand__area">
                    <img
                      src={logo}
                      className="m-brand__logo"
                      alt="Indian Island"
                      style={{paddingTop:30}}
                    />
                  </a>
                </div>
              </div>

              <nav
                className="m-nav js-navigation"
                data-navigation='{"activeClassName": "is-active"}'
              >
                <ul className="m-nav__list">
                <div>
                  <h4 className='menuColor' style={{color:"#C1483F"}}>
                    Indian & Nepali Restaurent
                  </h4>
                </div>
                <div>
                  <h4 className='menuColor'>
                  Godziny otwarcia
                  </h4>
                  <p className='menuColor'>
                  Pon - Nie  <br/>12:00 - 21:00
                  </p>
                </div>
                <div>
                  <h4 className='menuColor'>
                  Kontakt
                  </h4>
                  <p className='menuColor'>
                  733 423 100
                  <p>
                  Adres: Świętej Jadwigi 10/1a, 50-266 Wrocław
                  </p>
                  </p>
                </div>



                </ul>
              </nav>

              <div className="m-header__bottom">
                <div className="" data-restaurant-visible="">

                </div>
              </div>
            </div>
          </div>
        </header>
        </BrowserView>
        <MobileView>
        <div className="row mobvie" >
                <div className="">
                  <a href="/" className="">
                    <img
                      src={logo}
                      width="250"
                      alt="Indian Island"
                      style={{ padding:10}}
                    />
                  </a>
                </div>
              </div>
        </MobileView>

        {active && <div className="modal show bd-example-modal-lg" tabindex="-1" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" onClick={() => setActive(false)} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <iframe src="https://www.restaurantlogin.com/api/fb/mq36_n" width="870" height="500" className='center'/>
                </div>
                <div className="modal-footer">
                  <button onClick={() => setActive(false)} type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>}




        <main role="main" className="m-layout__content">
        <MobileView>
        <section className="m-section m-section--hero">
            <h2 className="sr-only">Polecamy</h2>
            <div className="m-hero ">
              <div className="m-hero__cover-wrapper m-hero__cover-wrapper--sticky">
                <div className="m-hero__bg-img">
                  <img
                    className="m-hero__cover-item"
                    srcset=""
                    sizes="100vw"
                    src={theme}
                    alt=""
                  />
                </div>
              </div>

              <div className="">
                <div className="m-slider js-slider ">
                  <div
                    className="m-slider__list js-slider__list"
                    id="hero-slider"
                    data-slick='{"asNavFor": "#", "fade": false, "autoplay": false, "autoplaySpeed": 5000}'
                  >
                    <article className="m-slider__list-item">
                      <div className="">
                        <div className=" u-text-center mx-auto ">
                          <h4 style={{fontSize:50}}> ZAMÓW ONLINE </h4>
                          <p>
                          Pyszne.pl  | Wolt | Uber Eats
                          </p>
                          <button onClick={(e) => { e.preventDefault(); window.location.href='https://www.restaurantlogin.com/api/fb/mq36_n'; }}
                              className="btn btn-lg m-hero__btn" style={{ color:"white", background:"#C1483F"}}>
                            See menu and Order online
                        </button>


                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              </div>
          </section>

        </MobileView>
        <BrowserView>
          <section className="m-section m-section--hero">
            <h2 className="sr-only">Polecamy</h2>
            <div className="m-hero ">
              <div className="m-hero__cover-wrapper m-hero__cover-wrapper--sticky">
                <div className="m-hero__bg-img">
                  <img
                    className="m-hero__cover-item"
                    srcset=""
                    sizes="100vw"
                    src={theme}
                    alt=""
                  />
                </div>
              </div>

              <div className="m-hero__content   mx-auto my-auto">
                <div className="m-slider js-slider ">
                  <div
                    className="m-slider__list js-slider__list"
                    id="hero-slider"
                    data-slick='{"asNavFor": "#", "fade": false, "autoplay": false, "autoplaySpeed": 5000}'
                  >
                    <article className="m-slider__list-item">
                      <div className="">
                        <div className=" u-text-center mx-auto ">
                          <h4 style={{fontSize:50}}> ZAMÓW ONLINE </h4>
                          <p>
                          Pyszne.pl  | Wolt | Uber Eats
                          </p>
                          <button onClick={() => setActive(true)}
                              className="btn btn-lg m-hero__btn" style={{ color:"white", background:"#C1483F"}}>
                            See menu and Order online
                        </button>


                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>




             {/* <aside className="m-hero__info-boxes mx-auto align-self-end">
                <div className="m-info-boxes">
                  <div
                    className="m-info-boxes__list "
                    data-restaurant-visible="misa-hindusa"
                  >
                    <article className="m-info-boxes__list-item">
                      <h4 className="m-info-boxes__title">Godziny otwarcia</h4>

                      <div className="m-info-boxes__description">
                        <div
                          className="m-restaurant-hours m-restaurant-hours--parent-align js-restaurant-hours js-restaurant-hours-opening"
                          data-restaurant-slug="misa-hindusa"
                          data-active-classes=""
                          data-inactive-classes=""
                        >
                          <div className="m-restaurant-hours__item m-restaurant-hours__item--singular js-restaurant-hours-present-day">
                            <span className="m-restaurant-hours__header js-restaurant-hours-header">
                              Dzisiaj:&nbsp;
                            </span>
                            <span className="m-restaurant-hours__data-wrapper">
                              <span className="m-restaurant-hours__data js-restaurant-hours-data"></span>

                              <button
                                className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                data-trigger="focus"
                                data-placement="auto top"
                                data-container="body"
                                data-target="#opening-hours-popover-misa-hindusa-1665046614121668666000"
                              >
                                <i className="icon-info-sign"></i>
                              </button>

                              <button
                                className="m-restaurant-hours__btn-collapse js-restaurant-hours-btn-collapse"
                                data-toggle="collapse"
                                data-target="#opening-hours-collapse-misa-hindusa-1665046614121668666000"
                              >
                                <span className="icon-caret-up js-icon-caret-up hide"></span>
                                <span className="icon-caret-down js-icon-caret-down"></span>
                              </button>
                            </span>
                            <span className="clearfix"></span>
                          </div>

                          <div
                            className="js-restaurant-hours-all-days collapse"
                            id="opening-hours-collapse-misa-hindusa-1665046614121668666000"
                          >
                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="1"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                poniedziałek
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 21:00
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#opening-hours-popover-misa-hindusa-1665046614121668666000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>

                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="2"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                wtorek
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 21:00
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#opening-hours-popover-misa-hindusa-1665046614121668666000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>

                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="3"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                środa
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 21:00
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#opening-hours-popover-misa-hindusa-1665046614121668666000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>

                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="4"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                czwartek
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 21:00
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#opening-hours-popover-misa-hindusa-1665046614121668666000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>

                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="5"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                piątek
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 21:00
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#opening-hours-popover-misa-hindusa-1665046614121668666000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>

                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="6"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                sobota
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 21:00
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#opening-hours-popover-misa-hindusa-1665046614121668666000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>

                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="7"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                niedziela
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 21:00
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#opening-hours-popover-misa-hindusa-1665046614121668666000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>
                          </div>

                          <div
                            className="js-restaurant-hours-popover"
                            style={{"display": "none"}}
                            id="opening-hours-popover-misa-hindusa-1665046614121668666000"
                          >
                            <strong className="pull-left js-restaurant-hours-popover-title"></strong>
                            <div className="pull-right m-popover__dismiss">
                              <button
                                type="button"
                                className="close js-dismiss-popover"
                              >
                                ×
                              </button>
                            </div>
                            <div className="clearfix"></div>
                            <div className="js-restaurant-hours-popover-details"></div>
                            <div className="js-restaurant-hours-popover-info"></div>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="m-info-boxes__list-item">
                      <h4 className="m-info-boxes__title">Godziny dostawy</h4>

                      <div className="m-info-boxes__description">
                        <div
                          className="m-restaurant-hours m-restaurant-hours--parent-align js-restaurant-hours js-restaurant-hours-online"
                          data-restaurant-slug="misa-hindusa"
                          data-active-classes=""
                          data-inactive-classes=""
                        >
                          <div className="m-restaurant-hours__item m-restaurant-hours__item--singular js-restaurant-hours-present-day">
                            <span className="m-restaurant-hours__header js-restaurant-hours-header">
                              Dzisiaj:&nbsp;
                            </span>
                            <span className="m-restaurant-hours__data-wrapper">
                              <span className="m-restaurant-hours__data js-restaurant-hours-data"></span>

                              <button
                                className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                data-trigger="focus"
                                data-placement="auto top"
                                data-container="body"
                                data-target="#online-hours-popover-misa-hindusa-1665046614125896323000"
                              >
                                <i className="icon-info-sign"></i>
                              </button>

                              <button
                                className="m-restaurant-hours__btn-collapse js-restaurant-hours-btn-collapse"
                                data-toggle="collapse"
                                data-target="#online-hours-collapse-misa-hindusa-1665046614125896323000"
                              >
                                <span className="icon-caret-up js-icon-caret-up hide"></span>
                                <span className="icon-caret-down js-icon-caret-down"></span>
                              </button>
                            </span>
                            <span className="clearfix"></span>
                          </div>

                          <div
                            className="js-restaurant-hours-all-days collapse"
                            id="online-hours-collapse-misa-hindusa-1665046614125896323000"
                          >
                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="1"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                poniedziałek
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 20:30
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#online-hours-popover-misa-hindusa-1665046614125896323000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>

                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="2"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                wtorek
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 20:30
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#online-hours-popover-misa-hindusa-1665046614125896323000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>

                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="3"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                środa
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 20:30
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#online-hours-popover-misa-hindusa-1665046614125896323000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>

                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="4"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                czwartek
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 20:30
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#online-hours-popover-misa-hindusa-1665046614125896323000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>

                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="5"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                piątek
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 20:30
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#online-hours-popover-misa-hindusa-1665046614125896323000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>

                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="6"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                sobota
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 20:30
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#online-hours-popover-misa-hindusa-1665046614125896323000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>

                            <div
                              className="m-restaurant-hours__item js-restaurant-hours-day"
                              data-day="7"
                            >
                              <span className="m-restaurant-hours__header js-restaurant-hours-header">
                                niedziela
                              </span>
                              <span className="m-restaurant-hours__data-wrapper">
                                <span className="m-restaurant-hours__data js-restaurant-hours-data">
                                  12:00 - 20:30
                                </span>

                                <button
                                  className="m-restaurant-hours__btn-popover js-restaurant-hours-btn-popover js-popover-advanced"
                                  data-trigger="focus"
                                  data-placement="auto top"
                                  data-container="body"
                                  data-target="#online-hours-popover-misa-hindusa-1665046614125896323000"
                                >
                                  <i className="icon-info-sign"></i>
                                </button>
                              </span>
                              <span className="clearfix"></span>
                            </div>
                          </div>

                          <div
                            className="js-restaurant-hours-popover"
                            style={{"display": "none"}}
                            id="online-hours-popover-misa-hindusa-1665046614125896323000"
                          >
                            <strong className="pull-left js-restaurant-hours-popover-title"></strong>
                            <div className="pull-right m-popover__dismiss">
                              <button
                                type="button"
                                className="close js-dismiss-popover"
                              >
                                ×
                              </button>
                            </div>
                            <div className="clearfix"></div>
                            <div className="js-restaurant-hours-popover-details"></div>
                            <div className="js-restaurant-hours-popover-info"></div>
                          </div>
                        </div>
                      </div>
                    </article>

                    <article className="m-info-boxes__list-item">
                      <h4 className="m-info-boxes__title">Kontakt</h4>

                      <div className="m-info-boxes__description">
                        728 933 013
                        <br />
                        <a
                          href="mailto:misahindusa@gmail.com"
                          className="u-link-unstyled underline-decoration"
                        >
                          misahindusa@gmail.com
                        </a>
                      </div>
                    </article>
                  </div>
                </div>
    </aside> */}
            </div>
          </section>
          </BrowserView>

          <section className="m-section" id="section-menu">
              <div className='text-center radius' style={{background:"#C1483F", margin:40, padding:10}}>
                Menu
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2 '>
                      <h1> PRZYSTAWKI </h1>
                      <h3 style={{color:"#C1483F"}}> Plain Papad</h3>
                      <p style={{color:"white"}}> crispy lentil pancakes</p>
                      <h5> 7 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Masala Papad (mild)</h3>
                      <p style={{color:"white"}}> crispy lentil pancakes with onion, cucumber and tomatoes</p>
                      <h5> 15 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Mix Veg Pakora </h3>
                      <p style={{color:"white"}}> fried, breaded vegetable rings: potato, champignons, broccoli, onion, zucchini, paneer cheese</p>
                      <h5> 24 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Aaloo tikka </h3>
                      <p style={{color:"white"}}> fried potato cutlets with mint and coriander, served with tamarind sauce</p>
                      <h5> 19 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Crispy Squid Rings</h3>
                      <p style={{color:"white"}}> crispy Squid Rings battered and deep fried, served with pickle dip</p>
                      <h5> 30 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Chicken Malai Tikka</h3>
                      <p style={{color:"white"}}> grilled chicken in sour cream and yoghurt, served with sweet mango sauce</p>
                      <h5> 34 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Chicken Pakora</h3>
                      <p style={{color:"white"}}> breaded chicken pieces with sauce</p>
                      <h5> 28 pln</h5>


                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <img src={starter} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                  <h3 style={{color:"#C1483F"}}> Samosa</h3>
                      <p style={{color:"white"}}> fried dumplings stuffed with potatoes, green peas and cashew nuts, served with tamarind sauce and mint sauce</p>
                      <h5> 20 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Shrimps Panko 6szt</h3>
                      <p style={{color:"white"}}> shrimps battered in panko and deep fried, served with spicy mayo</p>
                      <h5> 36 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Pudina Tikka</h3>
                      <p style={{color:"white"}}> grilled chicken marinated in yogurt & mint with Indian spices</p>
                      <h5> 33 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Tandoori Prawns 7 pcs</h3>
                      <p style={{color:"white"}}> grilled prawns marinated with paprika & onion in yogurt with Indian spices</p>
                      <h5> 40 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Chicken Tikka</h3>
                      <p style={{color:"white"}}> grilled chicken marinated in yogurt with Indian spices</p>
                      <h5> 30 pln</h5>
                </div>
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <h1> Soups </h1>
                      <h3 style={{color:"#C1483F"}}> Tomato soup</h3>
                      <p style={{color:"white"}}> Tomato soup</p>
                      <h5> 18 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Multani Soup</h3>
                      <p style={{color:"white"}}> lentel soup </p>
                      <h5> 19 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Daal Jimbu </h3>
                      <p style={{color:"white"}}> lentil soup with wild garlic</p>
                      <h5> 22 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Seafood soup</h3>
                      <p style={{color:"white"}}> soup with seafood and indian spicese</p>
                      <h5> 30 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Tom Kha Soups(veg|chicken|Prawns)</h3>
                      <p style={{color:"white"}}> thai soup with coconut milk</p>
                      <h5> 22 pln|24 pln|28 pln</h5>



                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2'>
                      <img src={soup} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                  <h3 style={{color:"#C1483F"}}> Tom Yum Soup (veg|chicken|Prawns)</h3>
                      <p style={{color:"white"}}> thai, sour and sweet soup</p>
                      <h5> 20 pln|23 pln|26 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Hot & Sour Soup (veg|chicken|Prawns)</h3>
                      <p style={{color:"white"}}> sour and sweet soup with indian spices</p>
                      <h5> 18 pln|22 pln|25 pln</h5>
                </div>
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <h1> Salads </h1>
                      <h3 style={{color:"#C1483F"}}> Chicken Salad (mild)</h3>
                      <p style={{color:"white"}}> Twith grilled chicken, cherry tomatoes and coriander pesto</p>
                      <h5> 30 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Paneer Salad</h3>
                      <p style={{color:"white"}}> with mix of lettuces, grilled Paneer cheese, cherry tomatoes and coriander pesto </p>
                      <h5> 29 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Shrimps Salad (mild) </h3>
                      <p style={{color:"white"}}> with grilled shrimps, cherry tomatoes and coriander pesto</p>
                      <h5> 34 pln</h5>
                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2'>
                      <img src={salad} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                  <h3 style={{color:"#C1483F"}}> Green Salad (mild)</h3>
                      <p style={{color:"white"}}> mix of lettuces with dressing</p>
                      <h5> 12 pln</h5>
                </div>
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <h1> Dishes from the Tandor oven </h1>
                      <h3 style={{color:"#C1483F"}}> Garlic Chicken Tikka (mild)</h3>
                      <p style={{color:"white"}}> pieces of chicken marinated in garlic, grilled in a tandoor oven</p>
                      <h5> 34 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Paneer Tikka (mild)</h3>
                      <p style={{color:"white"}}> pieces of Paneer cheese with vegetables, marinated in yogurt with spices, grilled in a tandoor oven </p>
                      <h5> 34 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Garlic Shrimps 7 pcs (semi spicy)</h3>
                      <p style={{color:"white"}}>shrimps marinated in garlic, grilled in a tandoor oven</p>
                      <h5> 42 pln</h5>
                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2'>
                      <img src={tandor} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                      <h3 style={{color:"#C1483F"}}> Seafood Mix (mild)</h3>
                      <p style={{color:"white"}}> seafood, marinated in Indian spices, grilled in a tandoor oven, served with vegetables</p>
                      <h5> 48 pln</h5>
                </div>
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <h1> Indo-chinese </h1>
                      <h3 style={{color:"#C1483F"}}> Chicken chilli (semi spicy)</h3>
                      <p style={{color:"white"}}> pieces of fried chicken, dipped in garlic, peppers and chilli sauce</p>
                      <h5> 40 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Paneer chilli (semi spicy)</h3>
                      <p style={{color:"white"}}> pieces of fried Paneer cheese, coated in garlic, peppers and chilli sauce </p>
                      <h5> 38 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Sesame Chicken (semi spicy)</h3>
                      <p style={{color:"white"}}>pieces of chicken boiled with paprika, tomato sauce, onion, dark soy sauce and fried with sesame-sweet chilli sauce</p>
                      <h5> 39 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Veg Hakka Noodles (mild)</h3>
                      <p style={{color:"white"}}>wheat chinese noodles fried with vegetables and Indian spices</p>
                      <h5> 35 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Chicken Pasta</h3>
                      <p style={{color:"white"}}>chicken noodles in curry sauce with tomato, onion, garlic, ginger and Indian spices</p>
                      <h5> 39 pln</h5>
                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2'>
                      <img src={salad} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                  <h3 style={{color:"#C1483F"}}> Manchurian Chicken (mild)</h3>
                      <p style={{color:"white"}}> deep-fried chicken, served with ketchup and soy sauce</p>
                      <h5> 38 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Shrimp Hakka Noodles 5 pcs (mild)</h3>
                      <p style={{color:"white"}}> wheat chinese noodles fried with shrimps, vegetables and Indian spices</p>
                      <h5> 42 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Chicken Hakka Noodles (mild)</h3>
                      <p style={{color:"white"}}> wheat chinese noodles fried with chicken, vegetables and Indian spices</p>
                      <h5> 38 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Seafood Pasta</h3>
                      <p style={{color:"white"}}>pasta with seafood and white wine with tomato, garlic and gingers</p>
                      <h5> 47 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Sweet and sour Chicken</h3>
                      <p style={{color:"white"}}>pieces of chicken cooked with onions, peppers, ginger, ketchup, sweet chilli and sweet and sour pineapple</p>
                      <h5> 39 pln</h5>
                </div>
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <h1> Chicken Dishes</h1>
                      <h3 style={{color:"#C1483F"}}> Chicken Korma</h3>
                      <p style={{color:"white"}}> pieces of grilled chicken in Indian sauce with coconut milk, cashew nut paste and sweet cream</p>
                      <h5> 43 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Butter Chicken</h3>
                      <p style={{color:"white"}}> pieces of grilled chicken prepared in a sauce with tomatoes, cream, coconut powder, butter and Indian spices </p>
                      <h5> 41 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Tawa Chicken (mild)</h3>
                      <p style={{color:"white"}}>pieces of grilled chicken baked in a tandoor oven and cooked with cumin, butter, ginger, garlic and Indian spices</p>
                      <h5> 43 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Methi Chicken (mild)</h3>
                      <p style={{color:"white"}}>pieces of grilled chicken cooked with ginger, garlic and Indian spices with fenugreek</p>
                      <h5> 41 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Chicken Kadhai (semi spicy)</h3>
                      <p style={{color:"white"}}> pieces of grilled chicken in fried onion, pepper and tomato mix with dried chilli, coriander and Indian spices </p>
                      <h5> 42 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Chicken Tikka Masala (semi spicy)</h3>
                      <p style={{color:"white"}}>pieces of grilled chicken in a spicy tomato and onion sauce</p>
                      <h5> 41 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Chicken Madras (spicy)</h3>
                      <p style={{color:"white"}}>pieces of grilled chicken in a very spicy curry sauce</p>
                      <h5> 43 pln</h5>
                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2'>
                      <img src={chicken_c} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                  <h3 style={{color:"#C1483F"}}>Chicken Palak (mild)</h3>
                      <p style={{color:"white"}}> pieces of grilled chicken with spinach and ginger with Indian spices</p>
                      <h5> 40 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Chicken Balti (mild)</h3>
                      <p style={{color:"white"}}> pieces of grilled chicken boiled with onions and tomatoes</p>
                      <h5> 42 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Chicken Curry (semi spicy)</h3>
                      <p style={{color:"white"}}> pieces of grilled chicken with ginger, onion and Indian spices with curry sauce</p>
                      <h5> 41 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Chicken Jalfrezi (semi spicy)</h3>
                      <p style={{color:"white"}}> pieces of grilled chicken cooked with ginger, garlic, onion, pepper and vegetables</p>
                      <h5> 42 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Chicken Vindaloo (spicy)</h3>
                      <p style={{color:"white"}}> pieces of grilled chicken cooked with potatoes, ginger, onion and spicy chilli sauce with Indian spicess</p>
                      <h5> 42 pln</h5>
                </div>
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <h1> Lamb Dishes </h1>
                      <h3 style={{color:"#C1483F"}}> Mutton Korma</h3>
                      <p style={{color:"white"}}> pieces of mutton in Indian sauce with coconut milk, cashew nut paste and sweet cream</p>
                      <h5> 51 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Butter Mutton</h3>
                      <p style={{color:"white"}}> pieces of mutton prepared in a sauce with tomatoes, cream, coconut powder, butter and Indian spices </p>
                      <h5> 50 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Tawa Mutton (mild)</h3>
                      <p style={{color:"white"}}>pieces of mutton baked in a tandoor oven and cooked with cumin, butter, ginger, garlic and Indian spices</p>
                      <h5> 51 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Methi Mutton (mild)</h3>
                      <p style={{color:"white"}}>pieces of mutton cooked with ginger, garlic and Indian spices with fenugreek</p>
                      <h5> 49 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Mutton Kadhai (semi spicy)</h3>
                      <p style={{color:"white"}}> pieces of mutton in fried onion, pepper and tomato mix with dried chilli, coriander and Indian spices </p>
                      <h5> 49 pln</h5>

                      <h3 style={{color:"#C1483F"}}> Mutton Madras (spicy)</h3>
                      <p style={{color:"white"}}>pieces of mutton in a very spicy curry sauce</p>
                      <h5> 49 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Mutton Rogan Josh (spicy)</h3>
                      <p style={{color:"white"}}>mutton with tomatoes, onion, ginger, clove, garlic, coriander, chilli and indian spices</p>
                      <h5> 48 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Mutton Daal (spicy)</h3>
                      <p style={{color:"white"}}>mutton with ginger, onion, coriander with lentil and indian spices</p>
                      <h5> 49 pln</h5>
                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2'>
                      <img src={mutton} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                  <h3 style={{color:"#C1483F"}}>Mutton  Palak (mild)</h3>
                      <p style={{color:"white"}}> pieces of mutton with spinach and ginger with Indian spices</p>
                      <h5> 48 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Mutton Jalfrezi (semi spicy)</h3>
                      <p style={{color:"white"}}> pieces of mutton cooked with ginger, garlic, onion, pepper and vegetables</p>
                      <h5> 50 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Mutton  Vindaloo (spicy)</h3>
                      <p style={{color:"white"}}> pieces of mutton cooked with potatoes, ginger, onion and spicy chilli sauce with Indian spicess</p>
                      <h5> 48 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Nepali Mutton curry (medium spicy)</h3>
                      <p style={{color:"white"}}> mutton in indian spices with vegetables and cream</p>
                      <h5> 53 pln</h5>
                </div>
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <h1> Shrimp </h1>
                      <h3 style={{color:"#C1483F"}}> Shrimps Korma</h3>
                      <p style={{color:"white"}}> shrimps in yellow indian sauce with coconut milk, cashew nut paste and sweet cream</p>
                      <h5> 52 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Butter Shrimps</h3>
                      <p style={{color:"white"}}>shrimps in sauce with tomato, cream, butter and indian spices </p>
                      <h5> 51 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Kadai Shrimps</h3>
                      <p style={{color:"white"}}>shrimps with pepper in tomato curry sauce</p>
                      <h5> 51 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Shrimps Masala (medium spicy)</h3>
                      <p style={{color:"white"}}>shrimps in spicy onion and tomato-pepper-mushroom sauce</p>
                      <h5> 51 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Balti Shrimps (medium spicy)</h3>
                      <p style={{color:"white"}}>shrimps in tomato sauce and indian spices </p>
                      <h5> 51 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Garlic Fish Curry (mild)</h3>
                      <p style={{color:"white"}}>salmon in garlic curry sauce</p>
                      <h5> 52 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Fish Tikka Masala (mild)</h3>
                      <p style={{color:"white"}}>salmon in onion-tomato sauce with indian spices</p>
                      <h5> 51 pln</h5>
                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2'>
                      <img src={shrimps} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                  <h3 style={{color:"#C1483F"}}> Mix Seafood Curry (medium spicy)</h3>
                      <p style={{color:"white"}}> sea food mix in curry sauce with indian spices</p>
                      <h5> 54 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Fish Vindaloo (spicy)</h3>
                      <p style={{color:"white"}}> salmon with ginger, onion and spicy chilli sauce with indian spices</p>
                      <h5> 52 pln</h5>
                </div>
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <h1> Vegetarian Dishes </h1>
                      <h3 style={{color:"#C1483F"}}> Veg Nawarattan Korma</h3>
                      <p style={{color:"white"}}>broccoli, cauliflower, zucchini, paneer cheese, carrots, mushrooms in cashew nuts sauce with pineapple and carica</p>
                      <h5> 39 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Mix Veg Curry</h3>
                      <p style={{color:"white"}}> cauliflower, green peas, mushrooms, broccoli, carrots and paneer cheese in indian curry sauce </p>
                      <h5> 38 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Daal Makhani</h3>
                      <p style={{color:"white"}}>lentil in butter-tomato sauce</p>
                      <h5> 36 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Aalu Bodi</h3>
                      <p style={{color:"white"}}>potatoes and beans in curry sauce with nepal spices</p>
                      <h5> 35 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Paneer Palak</h3>
                      <p style={{color:"white"}}> paneer cheese in spinach sauce </p>
                      <h5> 37 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Paneer Tikka Masala</h3>
                      <p style={{color:"white"}}>paneer cheese in spice tomato-onion sauce</p>
                      <h5> 38 pln</h5>
                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2'>
                      <img src={veg} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                  <h3 style={{color:"#C1483F"}}> Sahi Paneer</h3>
                      <p style={{color:"white"}}> paneer cheese in butter-tomato sauce</p>
                      <h5> 38 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Paneer Madras</h3>
                      <p style={{color:"white"}}> paneer cheese in spicy curry sauce</p>
                      <h5> 40 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Chana Masala </h3>
                      <p style={{color:"white"}}> chickpea stew with indian spices</p>
                      <h5> 36 pln</h5>
                </div>
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <h1> Rice </h1>
                      <h3 style={{color:"#C1483F"}}> Veg Biryani (mild)</h3>
                      <p style={{color:"white"}}> basmati rice with vegetables (cauliflower, broccoli, mushrooms, green peas) and indian spices</p>
                      <h5> 35 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Chicken Biryani (mild)</h3>
                      <p style={{color:"white"}}> basmati rice with chicken and indian spices </p>
                      <h5> 39 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Mutton Biryani (mild)</h3>
                      <p style={{color:"white"}}>basmati rice with mutton and indian spices</p>
                      <h5> 46 pln</h5>
                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2'>
                      <img src={biryani} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                  <h3 style={{color:"#C1483F"}}> Shrimps Biryani (mild)</h3>
                      <p style={{color:"white"}}> basmati rice with shrimps and indian spices</p>
                      <h5> 50 pln</h5>
                </div>
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <h1> Kids Menu </h1>
                      <h3 style={{color:"#C1483F"}}> Chicken Malai Tikka kids</h3>
                      <p style={{color:"white"}}> grilled chicken marinated in cream and yoghurt with sweet mango sauce</p>
                      <h5> 25 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Chicken Pakora kids</h3>
                      <p style={{color:"white"}}> battered chicken with cucumber and ketchup </p>
                      <h5> 21 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Mutton Korma kids (250g) + rice</h3>
                      <p style={{color:"white"}}>mutton with yellow indian sauce, coconut milk, cashew nuts paste and sweet cream</p>
                      <h5> 30 pln</h5>
                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2'>
                      <img src={kids} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                  <h3 style={{color:"#C1483F"}}> Chicken Korma kids (250g) + rice</h3>
                      <p style={{color:"white"}}> grilled chicken with coconut milk, cashew nuts paste and sweet cream</p>
                      <h5> 28 pln</h5>
                      <h3 style={{color:"#C1483F"}}>Veg Nawarattan Korma kids (250g) + rice</h3>
                      <p style={{color:"white"}}>cauliflower, green peas, mushrooms, paneer, broccoli, pineapple with cashew nuts sauce and fruits</p>
                      <h5> 26 pln</h5>
                </div>
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <h1> Tandoori Bread</h1>
                      <h3 style={{color:"#C1483F"}}> Plain Naan</h3>
                      <p style={{color:"white"}}> indian bread made of flour, eggs and milk, baked in Tandoori oven</p>
                      <h5> 10 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Butter Naan</h3>
                      <p style={{color:"white"}}>indian bread made of flour, eggs and milk, baked in Tandoori oven, served with butter </p>
                      <h5> 14 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Garlic Naan</h3>
                      <p style={{color:"white"}}>indian bread made of flour, eggs, milk and garlic, baked in Tandoori oven, served with butter</p>
                      <h5> 16 pln</h5>
                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2'>
                      <img src={bread} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                  <h3 style={{color:"#C1483F"}}> Sesame Naan</h3>
                      <p style={{color:"white"}}> indian bread made of flour, eggs, milk and sesame, baked in Tandoori oven, served with butter</p>
                      <h5> 15 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Plain Roti</h3>
                      <p style={{color:"white"}}>Plain Roti</p>
                      <h5> 10 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Garlic Roti</h3>
                      <p style={{color:"white"}}>indian bread made of wholegrain flour, eggs, milk and garlic, baked in Tandoori oven</p>
                      <h5> 12 pln</h5>
                </div>
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <h1> Desert </h1>
                      <h3 style={{color:"#C1483F"}}> Gulab Jamun 2 szt.</h3>
                      <p style={{color:"white"}}> fried dough balls with sweet syrup</p>
                      <h5> 15 pln</h5>
                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2'>
                      <img src={desert} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                </div>
              </div>
              <div className='  row shadow text-center'>
                <div className='col-10 '>
                  <div className='col-md-6 pt-5 pt-lg-0 order-2 order-lg-1'>
                      <h1> Non-Alcholic Drinks </h1>
                      <h3 style={{color:"#C1483F"}}> Mango Lassi 300ml</h3>
                      <p style={{color:"white"}}> Mango Lassi 300ml</p>
                      <h5> 14 pln</h5>
                      <h3 style={{color:"#C1483F"}}> mint Lassi 300ml</h3>
                      <p style={{color:"white"}}> smoothie with yoghurt and mint</p>
                      <h5> 16 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Sweet Lassi 300ml</h3>
                      <p style={{color:"white"}}>smoothie with yoghurt, sugar and indian spices</p>
                      <h5> 14 pln</h5>
                  </div>
                  <div className='col-md-6 pt-5 pt-lg-0 order-1 order-lg-2'>
                      <img src={lassi} width="900" style={{borderRadius:15}} alt='starter' />
                  </div>
                  <h3 style={{color:"#C1483F"}}> Salt Lassi 300ml</h3>
                      <p style={{color:"white"}}>smoothie with yoghurt, salt, sugar and indian spices</p>
                      <h5> 14 pln</h5>
                      <h3 style={{color:"#C1483F"}}> Nimbu Pani 300ml</h3>
                      <p style={{color:"white"}}>lemon water with indian spices</p>
                      <h5> 12 pln</h5>
                </div>
              </div>




          </section>



          <section className="m-section" id="section-reviews">
            <div className="m-container">
              <div className="">
                <header className="m-page-subheader u-text-center">
                  <h2 className="m-page-subheader__title">Opinie klientów</h2>

                  <p className="m-page-subheader__description">
                    99,2% klientów jest z nas zadowolonych!
                  </p>
                  <i className="icon-star" style={{color:"#C1483F"}}></i>
                  <i className="icon-star" style={{color:"#C1483F"}}></i>
                  <i className="icon-star" style={{color:"#C1483F"}}></i>
                  <i className="icon-star" style={{color:"#C1483F"}}></i>
                  <i className="icon-star" style={{color:"#C1483F"}}></i>
                </header>



                <p className="u-text-center u-base-spacing-t" >
                  <a href="/opinie" className="btn " style={{ color:"white", background:"#C1483F"}}>
                    Zobacz wszystkie opinie
                  </a>
                </p>
              </div>
            </div>
          </section>



          <section className="m-section">
            <div className="m-promo-section m-container">
              <div
                className="m-promo-section__inner  "
                style={{"background-image": "url('https://restaumatic-production.imgix.net/uploads/media_library/d89746888da2d9510b64a9f031eaecd5.gif?auto=compress&crop=focalpoint&fit=max&h=auto&w=1920');"}}
              >
                <div className="m-promo-section__container">
                  <div className="m-promo-section__item ">
                    <div className="m-promo-section__item-inner">
                      <p className="m-promo-section__text">
                        Pobierz appkę już teraz
                      </p>


                    </div>
                  </div>

                  <p className="m-promo-section__item u-mb0  ">
                    <img
                      src={logo} // restaurent galary
                      className="m-promo-section__image"
                      alt="Aplikacja mobilna"
                      loading="lazy"
                    />
                  </p>
                </div>
              </div>
            </div>
          </section>



          <section className="m-section m-section--footer">
            <div className="m-container">
              <div className="">
                <footer className="m-footer m-footer--variant-1">
                  <div className="m-footer__content">
                    <div className="container-fluid">
                      <div className="row row--flex">
                        <div className="col"></div>
                      </div>

                      <div className="" data-restaurant-visible="misa-hindusa">
                        <div className="row row--flex u-text-center">


                          <div className="col-12 col-md">
                            <h3 className="h4 u-small-spacing-y">
                              Dane kontaktowe
                            </h3>
                            <p className="u-base-spacing-b">
                              Adres email:{" "}
                              <a
                                href="mailto:theindianisland@gmail.com"
                                className="h4 u-link-unstyled"
                              >
                                theindianisland@gmail.com
                              </a>
                              <br />
                              Telefon:
                              <a href="tel:733-423-100" className="u-link-unstyled">
                              733 423 100
                              </a>
                              <p className="h5 u-small-spacing-y">
                              ND - ŚR 12:00 - 22:00 <br/>
                              CZW - SB 12:00 -23:00
                            </p>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row row--flex">
                        <div className="col-12">
                          <div className="m-footer__socials">
                            <ul className="list-inline u-base-spacing-b">
                              <li className="">
                                <a
                                  href="https://www.facebook.com/indianisland/"
                                  title="Facebook"
                                  className=""
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span
                                    className="icon-facebook"
                                    aria-hidden="true"
                                  ></span>
                                </a>
                              </li>



                              <li className="" style={{color:"red"}}>
                                <a
                                  href="https://www.instagram.com/indianisland/"
                                  title="Instagram"
                                  className=""
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span
                                    className="icon-instagram"
                                    aria-hidden="true"
                                  ></span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>




                      </div>

                      <div className="row row--flex">
                        <div className="col m-footer__info u-base-spacing-t">
                          <small className="m-footer-info flex-column">
                            <span className="m-footer-info__terms">
                              <a
                                href="#"
                                className="m-footer-info__link"
                                rel="nofollow"
                              >
                                Regulamin
                              </a>
                              |{" "}
                              <a
                                href="#"
                                className="m-footer-info__link"
                                rel="nofollow"
                              >
                                Polityka prywatności
                              </a>
                              |{" "}
                              <a
                                href="/zgody-marketingowe"
                                className="m-footer-info__link"
                                rel="nofollow"
                              >
                                Zgody marketingowe
                              </a>
                              {" "}

                            </span>

                            <span className="m-footer-info__info powered-by u-base-spacing-t u-text-center">
                              <span>
                              Copyright © 2022 Indian Island | All Rights Reserved. | design by Scopewit Sp.zo.o
                              </span>
                              <a
                                href="//www.scopewit.com"
                                className="u-link-wrapper"
                                target="_parent"
                                data-ga-action="Clicks on the Restaumatic link in the footer"
                                rel="nofollow"
                              >
                                &nbsp;

                              </a>
                            </span>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </section>
        </main>
       </div>
    );
};

export default Header;